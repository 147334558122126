var generic = generic || {};
var Model = Model || {};

/**
  * Singleton data model that handles collecting door inventory data for a single or set of skus.
  * This model can also be used to only call door data if needed.
  * When you pass a sku, or set of skus, with the door query, a door_inventory data object will be included.
  * Also, the rpc method changes from 'locator.doorsandevents' to 'locator.doorsandskustatus' when a sku is passed.
  * On success, the event 'DoorsInventory:query:success' is fired and other panels can listen in on that event.
  * Also available are helper methods that when passed a door id and sku id, can return the inventory object or just let
  *   you know if a sku is in-stock or not.
  * NOTE: Each store has a safety stock number and that safety stock is included with the totals coming from the RPC call.
  * You do not need to factor in the saftey stock when computing if a stock is availble or not.
*/
Model.DoorsInventory = (function() {
  var that = {};

  that.data = null;
  that.storeTypesReq = ['estee'];

  /*
    * Method used to call the load doors and inventory data query.
    * When a sku, or array of skus, is passed, it will return an inventory data object with the door data.
    * This is done by passing the 'use_header' flag, which tells the backend to get the location data via the akamai header data.
    * The default radius for the doors query is within a 50 mile radius unless an optional radius is passed.
    * The queryFieldss are set within the model using the queryFields array object.
    * You can pass an optional callback function or use the event 'DoorsInventory:query:success' on success to receive results data.
    * Here is an example of the return data object with 'door_inventory'.
    *    "value":{
    *     "driving_directions":"yes",
    *     "longitude":"-117.9073244",
    *     "count":8,
    *     "country_code":"us",
    *     "latitude":"33.6834142",
    *     "doors":[446387,185639,165341,165395,165411,429482,429489,429376],
    *     "door_inventory": { ..Inventory objects by door.. },
    *     "results":{ ..Result door data.. }
    *   }
  */
  that.load = function(args) {
    var that = this;
    var _errors = [];
    var _args = args || {};
    var _zip = _args.zip || null;
    var _radius = _args.radius || 10;
    var _state = _args.state || null;
    var _city = _args.city || null;
    var _country = _args.country || null;
    if (_city === 'Macau (SAR of China)') {
      _country = 'Macau';
    } else if (_city === '澳門特別行政區') {
      _country = '澳門';
    }
    var _region_id = _args.region_id || null;
    var _language_id = _args.language_id || null;
    var _store_types = _args.inv_store_types ? _args.inv_store_types.split(',') : that.storeTypesReq;
    var _display = _args.display && _args.display !== '_' ? _args.display : null;
    var _skus = _.isArray(_args.skus) ? _args.skus : [_args.skus];
    var _callback = _args.callback || null;
    var _useHeader = _args.useHeader || false;
    var _safetyStock = _args.safetyStock || 0;
    var _uom = _args.uom || 'km';

    var queryArgs = {
      params: [{
        'fields': that.queryFields,
        'radius': _radius,
        'display': _display,
        'country': _country,
        'region_id': _region_id,
        'language_id': _language_id,
        'inv_store_types': _store_types,
        'skus': _skus,
        'safetyStock': _safetyStock,
        'uom': _uom
      }]
    };
    if (_city) {
      // If city are entered
      queryArgs.params[0].city = _city;
    } else if (_useHeader) {
      // User header param is set.
      queryArgs.params[0].use_header = 1;
    } else {
      // When all else fails.
      _errors.push({ key: 'err_missing_city' });
    }
    // Override to force the location for headers.
    var queryParams = document.location.search;
    var hasTestingOverride = queryParams.match(/OVERRIDE_CITY=(\w*)/);
    if (hasTestingOverride && hasTestingOverride[1] && (!_zip && !_state && !_city)) {
      queryArgs.params[0].fake_mode = hasTestingOverride[1]; // Param for testing to be removed
      queryArgs.params[0].show_message = 1; //Param for testing to be removed
      queryArgs.params[0].use_header = 1;
    }

    queryArgs.onSuccess = function(results) {
      if (typeof _callback == 'function') {
        _callback(results);
      }
      $(document).trigger('DoorsInventory:query:success', results);
    };

    queryArgs.onFailure = function(results) {
      if (_callback) {
        _callback(results);
      }
      $(document).trigger('DoorsInventory:query:failure', results);
    };

    if (_errors[0]) {
      $(document).trigger('DoorsInventory:query:failure', {
        errors: _errors
      });
    } else {
      // JSON-RPC request
      that.query(queryArgs);
    }
  };

  /*
    * Method responsible for making the generic jsonrpc fetch to backend.
    * If skus are passed, the 'locator.doorsandskustatus' method is called.
    * Otherwise, the default method 'locator.doorsandevents' is called instead.
    * The 'locator.doorsandskustatus' method returns a 'door_inventory' with sku info in the door data.
    * The 'locator.doorsandevents' will only return door data.
    * The query also handles an onFailure and onSuccess callback.
    * If successful, it will return the result along with filtering the data and setting the class var data.
  */
  that.query = function(args) {
    var that = this;
    var _args = args || {};
    var method = 'locator.doorsandevents';

    // Override method with locator.doorsandskusstatus when skus are passed.
    if (_args.params && _args.params[0] && _args.params[0].skus) {
      method = 'locator.doorsandskustatus';
    }

    generic.jsonrpc.fetch({
      method: method,
      params: _args.params,
      onSuccess: function(r) {
        if (_args.onSuccess && typeof _args.onSuccess == 'function') {
          var result = r.getValue();
          result.params = _args.params;
          result.sorted = that.filterResults(result, _args);
          result.results = that.updateResults(result);
          that.data = result;
          _args.onSuccess(result);
        }
      },
      onFailure: function(r) {
        if (_args.onFailure && typeof _args.onFailure == 'function') {
          _args.onFailure(r);
        }
      }
    });
  };

  that.updateResults = function(rawResultData) {
    if (!rawResultData || !rawResultData.results) {
      return null;
    }
    var modResults = $.extend({}, rawResultData.results);
    for (var result in modResults) {
      if (modResults[result] && modResults[result].DISTANCE) {
        var distance = modResults[result].DISTANCE;
        var match = distance.match(/\d*.\d/);
        if (match) {
          modResults[result].distance_short = parseFloat(match[0]);
        }
      }
    }
    return modResults;
  };

  /*
    * Method responsible for filtering the raw result data by distance.
    * It will iterate through all results and order them by using the door DISTANCE param.
    * First, if a maxDistance is passed, it will filter out doors over that limit.
    * Then it will sort the data by distance by creating a sortedDoorIds array to organize the doors.
`   * Once that list is created, the door data objects will be placed into a sortedResults.
    * If a doorLimit is passed, it will stop adding doors to the sortedResults.
    * Otherwise, it will use the array length as the limit.
  */
  that.filterResults = function(data, args) {
    var that = this;
    var _args = args || {};
    var _resultData = $.extend({}, data);

    if (data.door_inventory) {
      for (var d in data.door_inventory) {
        var door = data.door_inventory[d];
        // set inventory status for each door
        // fold doors_inventory data into doors list
        _.extend(_resultData.results[d], door);
      }
    }
    //
    // filter out results that exceed max distance
    if (_resultData.maxDistance) { // max distance exists
      var filteredResults = {};
      for (var result in _resultData.results) {
        if (_resultData.results[result].DISTANCE < _resultData.maxDistance) {
          filteredResults[result] = data.results[result];
        }
      }
      _resultData.results = filteredResults;
    }

    // asc. sort by distance
    var sortedResults = [];
    var r = _resultData.results ? _resultData.results : {};
    var sortedDoorIds = _resultData.doors || [];

    function sortByDistance(a, b) {
      var aDist = !r[a].DISTANCE || isNaN(r[a].DISTANCE) ? 0 : parseFloat(r[a].DISTANCE);
      var bDist = !r[b].DISTANCE || isNaN(r[b].DISTANCE) ? 0 : parseFloat(r[b].DISTANCE);

      if (aDist > bDist) {
        return 1;
      } else if (aDist < bDist) {
        return -1;
      }
      return 0;
    }

    sortedDoorIds.sort(sortByDistance);

    var params = data.params && data.params[0] ? data.params[0] : null;
    var doorLimit = params.display || sortedDoorIds.length;
    for (var i = 0, len = parseInt(doorLimit); i < len; i++) {
      sortedResults.push(r[sortedDoorIds[i]]);
    }
    //
    // filter out results that are not free-standing stores
    var storeTypes = _args.inv_store_types || that.storeTypesReq;
    sortedResults = _.filter(sortedResults, function(door) {
      return storeTypes.indexOf(door.STORE_TYPE) > -1;
    });
    return sortedResults;
  };

  /**
    * Default query fields for the doors and inventory queries.
  */
  that.queryFields = 'DOOR_ID, DOORNAME, STORE_HOURS, ADDRESS, ADDRESS2, STATE_OR_PROVINCE, CITY, REGION, COUNTRY, ZIP_OR_POSTAL, PHONE1, STORE_TYPE, LONGITUDE, LATITUDE, JDA_STORE_NUMBER, STORE_TYPE, SUB_HEADING';

  /**
    * Default query fields for the doors queries only.
  */
  that.queryDoorsFields = 'DOOR_ID';

  return that;
}());
